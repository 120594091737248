var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Shifts",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('div', {
    staticClass: "OverviewCards"
  }, [_c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('p', [_vm._v("Schichtanzahl")]), _c('h3', [_vm._v(_vm._s(_vm.shiftCount))])]), _c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('p', [_vm._v("Umsatz der Schichten")]), _c('h3', [_vm._v(_vm._s(_vm.shiftSum))])]), _c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('p', [_vm._v("Fahrten mit Zuschlägen")]), _c('h3', [_vm._v(_vm._s(_vm.finalExtrasAmountCount.count))])]), _c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('p', [_vm._v("Summe der Zuschläge")]), _c('h3', [_vm._v(_vm._s(_vm.round2d(_vm.finalExtrasAmountCount.amount / 100).format()) + "€")])]), _c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('p', [_vm._v("Bar-Zahlungen")]), _c('h3', [_vm._v(" " + _vm._s(_vm.priceToEuroString(_vm.totalCashPayments.amount)) + " ")])]), _c('Card', {
    attrs: {
      "variant": "info"
    }
  }, [_c('p', [_vm._v(" Unbar-Zahlungen "), _vm.totalNonCashPayments.amount ? _c('i', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.left",
      value: {
        content: _vm.tooltipContent,
        html: true
      },
      expression: "{ content: tooltipContent, html: true }",
      modifiers: {
        "left": true
      }
    }],
    staticClass: "ri-information-line"
  }) : _vm._e()]), _c('h3', [_vm._v(" " + _vm._s(_vm.priceToEuroString(_vm.totalNonCashPayments.amount)) + " ")])])], 1), _c('Toolbar', [_c('div', [_c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "placeholder": "Fahrer",
      "selected": _vm.selectedEmployee,
      "items": _vm.drivers.filter(function (d) {
        return d.isVisible;
      })
    },
    on: {
      "onItemSelect": _vm.handleEmployeeSelect
    }
  }), _c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "selected": _vm.selectedCar,
      "items": _vm.cars,
      "placeholder": "Kennzeichen"
    },
    on: {
      "onItemSelect": _vm.handleCarSelect
    }
  }), _c('Datepicker', {
    attrs: {
      "startDate": _vm.selectedFrom,
      "endDate": _vm.selectedTo
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Button', {
    attrs: {
      "size": "small",
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleQuery
    }
  }, [_vm._v(" Anzeigen ")]), _c('PageNavigationToolbar', {
    attrs: {
      "selectedCar": _vm.selectedCar,
      "selectedEmployee": _vm.selectedEmployee
    }
  })], 1), _c('div', [_c('Download', {
    attrs: {
      "title": "Einfache PDF",
      "type": "pdf",
      "size": "small"
    },
    on: {
      "onDownload": function onDownload($event) {
        return _vm.handleDownload(undefined, 'simplified');
      }
    }
  }), _c('Download', {
    attrs: {
      "title": "Detaillierte PDF",
      "type": "pdf",
      "size": "small"
    },
    on: {
      "onDownload": function onDownload($event) {
        return _vm.handleDownload(undefined, 'detailed');
      }
    }
  }), _vm.isMobile ? _c('Checkbox', {
    attrs: {
      "size": "small",
      "keyName": "includeWrongTrips",
      "name": "includeWrongTrips",
      "value": _vm.includeWrongTrips,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onCheck": _vm.handleIncludeWrongTrips
    }
  }, [_vm._v(" Falschanmeldungen ")]) : _vm._e(), _vm.isMobile ? _c('Checkbox', {
    attrs: {
      "size": "small",
      "keyName": "showAllTrips",
      "name": "showAllTrips",
      "value": _vm.showAllTrips,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onCheck": _vm.handleShowAllTrips
    }
  }, [_vm._v(" Alle Fahrten anzeigen ")]) : _vm._e()], 1)]), !_vm.isMobile ? _c('Toolbar', [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "20px"
    }
  }, [_c('Checkbox', {
    attrs: {
      "size": "small",
      "keyName": "includeWrongTrips",
      "name": "includeWrongTrips",
      "value": _vm.includeWrongTrips,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onCheck": _vm.handleIncludeWrongTrips
    }
  }, [_vm._v(" Falschanmeldungen ")]), _c('Checkbox', {
    attrs: {
      "size": "small",
      "keyName": "showAllTrips",
      "name": "showAllTrips",
      "value": _vm.showAllTrips,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onCheck": _vm.handleShowAllTrips
    }
  }, [_vm._v(" Alle Fahrten anzeigen ")])], 1)]) : _vm._e(), _c('ShiftsTable', {
    attrs: {
      "shifts": _vm.filteredShifts || _vm.shifts,
      "showAllTrips": _vm.showAllTrips
    },
    on: {
      "onRowHover": _vm.handleRowHover,
      "onDriverClick": _vm.handleDriverClick,
      "onRowLeave": _vm.handleRowLeave,
      "onTripClick": _vm.handleTripClick,
      "onHeaderClick": _vm.handleHeaderClick
    }
  }), _c('Modal', {
    attrs: {
      "title": _vm.selectedShift && "Markieren Sie die Fahrt von\n".concat(_vm.selectedShift.driver.name),
      "show": _vm.modal.active,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.saveTripType
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "isLoading": _vm.isSavingTripType
    },
    on: {
      "onModalClose": _vm.handleModalClose
    }
  }, [_c('b', [_vm._v(" Zahlungsart ")]), _vm._l(_vm.tripTypes, function (tripType, index) {
    return _c('div', {
      key: index,
      staticClass: "RadioInput"
    }, [_c('input', {
      attrs: {
        "type": "radio",
        "id": tripType.key,
        "name": "tripTypeSelection"
      },
      domProps: {
        "value": tripType.key,
        "checked": _vm.tripTypeIsChecked(tripType)
      },
      on: {
        "click": _vm.handleTripTypeSelect,
        "focus": _vm.handleFocus,
        "blur": _vm.handleBlur
      }
    }), _c('label', {
      attrs: {
        "for": tripType.key
      }
    }, [_vm._v(_vm._s(tripType.label))])]);
  }), _c('div', [_c('br'), _c('label', {
    attrs: {
      "for": "paymentAmount"
    }
  }, [_vm._v("Erhaltener Betrag")]), _c('br'), _c('span', {
    staticClass: "Hint"
  }, [_vm._v(" Maximalbetrag: ")]), _c('span', {
    staticClass: "Hint"
  }, [_vm._v(_vm._s(_vm.selectedTrip ? "".concat(_vm.round2d(_vm.selectedTrip.totalAmount / 100).format(), "\u20AC") : ''))]), _c('br'), _c('Input', {
    attrs: {
      "type": "text",
      "pattern": "[0-9]+([,][0-9]+)?",
      "id": "paymentAmount",
      "name": "paymentAmount",
      "keyName": "paymentAmount",
      "value": _vm.wrongTripAmount
    },
    on: {
      "onKeyPress": _vm.handlePaymentAmountChange
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v(" Schnellwahl: ")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "5px"
    }
  }, [_c('button', {
    staticClass: "Tag",
    on: {
      "click": function click($event) {
        _vm.wrongTripAmount = 0;
      }
    }
  }, [_vm._v("0 €")]), _c('button', {
    staticClass: "Tag",
    on: {
      "click": function click($event) {
        _vm.wrongTripAmount = _vm.round2d(_vm.selectedTrip.totalAmount / 100).format();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedTrip ? "".concat(_vm.round2d(_vm.selectedTrip.totalAmount / 100).format(), "\u20AC") : '') + " ")])])], 1)], 2), _c('Modal', {
    attrs: {
      "title": "Fahrer ändern",
      "show": _vm.showDriverChangeModal,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleDriverChange
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "isLoading": _vm.isChangingDriver
    },
    on: {
      "onModalClose": function onModalClose($event) {
        _vm.showDriverChangeModal = false;
      }
    }
  }, [_c('p', [_vm._v(" Neuen Fahrer für Schicht auswählen: ")]), _c('br'), _c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "placeholder": "Fahrer",
      "selected": _vm.selectedDriver,
      "items": _vm.drivers.filter(function (d) {
        return d.isVisible;
      })
    },
    on: {
      "onItemSelect": _vm.handleDriverSelect
    }
  }), _c('br'), _c('span', {
    staticClass: "Hint"
  }, [_vm._v(" Schnellwahl: ")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "5px"
    }
  }, [_c('button', {
    staticClass: "Tag",
    on: {
      "click": function click($event) {
        return _vm.handleSelectedDriverChange('Betrieb');
      }
    }
  }, [_vm._v(" Betrieb ")]), _c('button', {
    staticClass: "Tag",
    on: {
      "click": function click($event) {
        return _vm.handleSelectedDriverChange('Werkstatt');
      }
    }
  }, [_vm._v(" Werkstatt ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
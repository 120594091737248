<template>
    <div class="ShiftsTable">
        <BaseDashboardTable
            ref="dashboardTable"
            :columns="columns"
            :items="formattedShifts"
            :sort="sortState"
            :empty-message="'Keine Schichten vorhanden'"
            :use-hierarchical-rows="true"
            :searchable-columns="[
                'driver',
                'sequenceNumber',
                'licenseNumber',
                'startAt',
                'endAt',
                'finalTotalAmount',
            ]"
            :search-placeholder="'Schichten durchsuchen...'"
            @sort-changed="handleSortChanged"
            @row-click="handleShiftClick"
            @toggle-expand="handleToggleExpand"
            @row-hover="args => $emit('onRowHover', args)"
            @row-leave="args => $emit('onRowLeave', args)"
        >
            <template #title>
                <h3>Schichten</h3>
            </template>

            <!-- Custom column for driver -->
            <template #column-driver="{ item }">
                <span
                    :style="{ color: item.hasError ? 'var(--color-red-light)' : 'inherit' }"
                    v-tooltip="'Fahrer ändern'"
                    @click.stop="handleDriverNameClick(item)"
                    class="driver-name"
                >
                    {{ item.driver }}
                </span>
            </template>

            <!-- Custom column for payment amounts -->
            <template #column-finalTotalAmount="{ item }">
                <span :class="{ 'warning-text': item.hasWarning }">
                    {{ item.finalTotalAmount }}
                </span>
            </template>

            <template #column-subtractedTotalAmount="{ item }">
                <span v-if="item.subtractedTotalAmount !== '--:--'" class="warning-text">
                    {{ item.subtractedTotalAmount }}
                </span>
                <span v-else>{{ item.subtractedTotalAmount }}</span>
            </template>
        </BaseDashboardTable>
    </div>
</template>

<script>
import { format } from 'date-fns';
import { round2d } from '@/lib/helper';
import BaseDashboardTable from '@/components/BaseDashboardTable';
import Button from '@/components/widgets/Button';

export default {
    name: 'ShiftsTable',
    components: {
        BaseDashboardTable,
        Button,
    },
    props: {
        shifts: {
            type: Array,
            default: () => [],
        },
        showAllTrips: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            sortState: {
                column: 'startAt',
                ascending: false,
            },
            columns: [
                {
                    key: 'driver',
                    label: 'Fahrer Name',
                    width: '10%',
                    tooltip: 'Fahrer Name',
                },
                {
                    key: 'sequenceNumber',
                    label: 'Schicht',

                    tooltip: 'Schicht Nummer',
                },
                {
                    key: 'licenseNumber',
                    label: 'Kennz.',

                    tooltip: 'Kennzeichen',
                },
                {
                    key: 'startAt',
                    label: 'Start',

                    tooltip: 'Abfahrt',
                },
                {
                    key: 'endAt',
                    label: 'Ende',

                    tooltip: 'Ankunft',
                },
                {
                    key: 'duration',
                    label: 'Zeit',

                    tooltip: 'Dauer',
                    sortable: true,
                },
                {
                    key: 'totalDistance',
                    label: 'Ges.KM',
                    tooltip: 'Gesamt KM',
                },
                {
                    key: 'hiredDistance',
                    label: 'Bes.KM',

                    tooltip: 'Besetzt KM',
                },
                {
                    key: 'forHireDistance',
                    label: 'Leer KM',
                },
                {
                    key: 'finalExtrasAmount',
                    label: 'Zuschl.',

                    tooltip: 'Zuschläge',
                },
                {
                    key: 'subtractedTotalAmount',
                    label: 'Fehlb.',

                    tooltip: 'Fehlbetrag',
                },
                {
                    key: 'finalTotalAmount',
                    label: 'Umsatz',
                },
            ],
            expandedShiftIds: [],
        };
    },
    computed: {
        formattedShifts() {
            const formattedData = [];

            this.shifts.forEach(shift => {
                const shiftId = shift.uuid || `shift-${shift.sequenceNumber}`;
                const isExpanded = this.expandedShiftIds.includes(shiftId) || this.showAllTrips;
                const hasError = this.hasError(shift);
                const hasWarning = this.hasWarning(shift);

                // Create parent row for shift
                const parentRow = {
                    driver: shift.driver?.name || '',
                    sequenceNumber: shift.sequenceNumber || '',
                    licenseNumber: shift.licenseNumber || '',
                    startAt: format(new Date(shift.startAt), 'dd.MM - HH:mm'),
                    endAt: format(new Date(shift.endAt), 'dd.MM - HH:mm'),
                    duration: this.formatDuration(shift.startAt, shift.endAt),
                    totalDistance: shift.totalDistance
                        ? round2d(shift.totalDistance / 1000).format()
                        : '--:--',
                    hiredDistance: shift.hiredDistance
                        ? round2d(shift.hiredDistance / 1000).format()
                        : '--:--',
                    forHireDistance: shift.forHireDistance
                        ? round2d(shift.forHireDistance / 1000).format()
                        : '--:--',
                    finalExtrasAmount: shift.finalExtrasAmount
                        ? `${round2d(shift.finalExtrasAmount / 100).format()}€`
                        : '--:--',
                    subtractedTotalAmount: shift.subtractedTotalAmount
                        ? `-${round2d(shift.subtractedTotalAmount / 100).format()}€`
                        : '--:--',
                    finalTotalAmount: `${round2d(shift.finalTotalAmount / 100).format()}€`,

                    // Raw values for sorting
                    _startAt: new Date(shift.startAt),
                    _endAt: new Date(shift.endAt),
                    _duration: this.getDurationInMinutes(shift.startAt, shift.endAt),
                    _driver: (shift.driver?.name || '').toLowerCase(),
                    _sequenceNumber: shift.sequenceNumber || 0,
                    _licenseNumber: (shift.licenseNumber || '').toLowerCase(),
                    _totalDistance: shift.totalDistance || 0,
                    _hiredDistance: shift.hiredDistance || 0,
                    _forHireDistance: shift.forHireDistance || 0,
                    _finalExtrasAmount: shift.finalExtrasAmount || 0,
                    _subtractedTotalAmount: shift.subtractedTotalAmount || 0,
                    _finalTotalAmount: shift.finalTotalAmount || 0,

                    // Metadata
                    originalShift: shift,
                    hasError,
                    hasWarning,
                    _expanded: isExpanded,
                    _id: shiftId,
                    _hasChildren: shift.trips && shift.trips.length > 0,
                    _isChild: false,
                    _level: 0,
                };

                formattedData.push(parentRow);

                // Add child rows for trips if expanded
                if (isExpanded && shift.trips && shift.trips.length > 0) {
                    shift.trips
                        .sort((a, b) => b.sequenceNumber - a.sequenceNumber)
                        .forEach((trip, index) => {
                            const hasWarning = this.hasTripWarning(trip);
                            const isEmptyTrip =
                                trip.hiredDistance === 0 && trip.finalTotalAmount === 0;
                            const isFlatrate = trip.isFlatrate;
                            const childRow = {
                                sequenceNumber: trip.sequenceNumber || '',
                                startAt: format(new Date(trip.startAt), 'dd.MM - HH:mm'),
                                endAt: format(new Date(trip.endAt), 'dd.MM - HH:mm'),
                                duration: this.formatDuration(trip.startAt, trip.endAt),
                                totalDistance: round2d(trip.totalDistance / 1000).format(),
                                hiredDistance: round2d(trip.hiredDistance / 1000).format(),
                                forHireDistance: round2d(trip.onCallDistance / 1000).format(),
                                finalExtrasAmount: trip.finalExtrasAmount
                                    ? `${round2d(trip.finalExtrasAmount / 100).format()}€`
                                    : '--:--',
                                subtractedTotalAmount: hasWarning
                                    ? `-${round2d(trip.subtractedTotalAmount / 100).format()}€`
                                    : '--:--',
                                finalTotalAmount: `${round2d(
                                    trip.finalTotalAmount / 100,
                                ).format()}€`,

                                // Raw values for sorting
                                _startAt: new Date(trip.startAt),
                                _endAt: new Date(trip.endAt),
                                _duration: this.getDurationInMinutes(trip.startAt, trip.endAt),
                                _sequenceNumber: trip.sequenceNumber || 0,
                                _totalDistance: trip.totalDistance || 0,
                                _hiredDistance: trip.hiredDistance || 0,
                                _forHireDistance: trip.onCallDistance || 0,
                                _finalExtrasAmount: trip.finalExtrasAmount || 0,
                                _subtractedTotalAmount: trip.subtractedTotalAmount || 0,
                                _finalTotalAmount: trip.finalTotalAmount || 0,

                                // Metadata
                                originalTrip: trip,
                                isEmptyTrip,
                                hasWarning,
                                isFlatrate,
                                _id: `${shiftId}-trip-${index}`,
                                _parentId: shiftId,
                                _isChild: true,
                                _level: 1,
                                _hasChildren: false,
                            };

                            formattedData.push(childRow);
                        });
                }
            });

            // Apply sorting while maintaining hierarchy
            if (this.sortState.column) {
                const sortKey = this.sortState.column.startsWith('_')
                    ? this.sortState.column
                    : `_${this.sortState.column}`;

                // Separate parents and children
                const parentRows = formattedData.filter(row => !row._isChild);
                const childRowsByParent = {};

                formattedData.forEach(row => {
                    if (row._isChild && row._parentId) {
                        if (!childRowsByParent[row._parentId]) {
                            childRowsByParent[row._parentId] = [];
                        }
                        childRowsByParent[row._parentId].push(row);
                    }
                });

                // Sort parent rows
                parentRows.sort((a, b) => {
                    const valueA = a[sortKey];
                    const valueB = b[sortKey];
                    return this.compareValues(valueA, valueB, this.sortState.ascending);
                });

                // Sort child rows for each parent
                Object.keys(childRowsByParent).forEach(parentId => {
                    childRowsByParent[parentId].sort((a, b) => {
                        const valueA = a[sortKey];
                        const valueB = b[sortKey];
                        return this.compareValues(valueA, valueB, this.sortState.ascending);
                    });
                });

                // Rebuild sorted array
                const sortedData = [];
                parentRows.forEach(parent => {
                    sortedData.push(parent);
                    if (parent._expanded && childRowsByParent[parent._id]) {
                        sortedData.push(...childRowsByParent[parent._id]);
                    }
                });

                return sortedData;
            }

            return formattedData;
        },
    },
    methods: {
        formatDuration(startAt, endAt) {
            const duration = new Date(endAt) - new Date(startAt);
            const hours = Math.floor(duration / (1000 * 60 * 60));
            const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
            return `${hours}h ${minutes}m`;
        },
        getDurationInMinutes(startAt, endAt) {
            const duration = new Date(endAt) - new Date(startAt);
            return Math.floor(duration / (1000 * 60)); // Return total minutes
        },
        compareValues(valueA, valueB, ascending) {
            if (valueA instanceof Date && valueB instanceof Date) {
                return ascending ? valueA - valueB : valueB - valueA;
            }
            if (typeof valueA === 'string' && typeof valueB === 'string') {
                return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            }
            return ascending ? valueA - valueB : valueB - valueA;
        },
        handleSortChanged(sort) {
            this.sortState = {
                column: sort.column,
                ascending: sort.ascending,
            };
        },
        handleToggleExpand({ item }) {
            const shiftId = item._id;
            const index = this.expandedShiftIds.indexOf(shiftId);
            if (index >= 0) {
                this.expandedShiftIds.splice(index, 1);
            } else {
                this.expandedShiftIds.push(shiftId);
            }
        },
        handleShiftClick({ item, event }) {
            if (event && event.target.closest('.driver-name')) {
                return; // Skip if clicking on driver name
            }

            if (item._isChild) {
                this.$emit('onTripClick', {
                    i: this.shifts.findIndex(s => s.uuid === item._parentId),
                    j: item.originalTrip.uuid,
                });
            }
        },
        handleDriverNameClick(item) {
            if (!item._isChild) {
                this.$emit('onDriverClick', item.originalShift.uuid);
            }
        },
        hasWarning(entry) {
            return entry?.subtractedTotalAmount !== 0 || !entry.driver?.name;
        },
        hasError(entry) {
            const drivers = JSON.parse(localStorage.getItem('drivers'));
            const driver = drivers.find(d => d.id === entry.driverNumber);
            const driverNumberIsName = entry.driver?.name == entry.driver.driverNumber;
            return driverNumberIsName;
        },
        hasTripWarning(entry) {
            return entry?.subtractedTotalAmount !== 0;
        },
        handleLoadMore() {
            this.currentPage += 1;
        },
    },
};
</script>

<style lang="scss" scoped>
.ShiftsTable {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    :deep(.BaseDashboardTable) {
        height: 100%;
        display: flex;
        flex-direction: column;

        .BaseDashboardTable-container {
            flex: 1;
            min-height: 200px;

            table {
                table-layout: fixed;

                tbody {
                    tr {
                        &.child-row {
                            td {
                                // Ensure child rows maintain same width as parent
                                width: inherit;
                                min-width: inherit;
                                max-width: inherit;

                                &:first-child {
                                    padding-left: 40px; // Add indent for child rows
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .driver-name {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        padding: 2px 0;

        &:hover {
            text-decoration: underline;
            color: var(--color-blue);
        }
    }

    .warning-text {
        color: var(--color-red);
    }

    .Button--LoadMore {
        margin-top: 16px;
        width: 100%;
    }
}
</style>

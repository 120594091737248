var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ShiftsTable"
  }, [_c('BaseDashboardTable', {
    ref: "dashboardTable",
    attrs: {
      "columns": _vm.columns,
      "items": _vm.formattedShifts,
      "sort": _vm.sortState,
      "empty-message": 'Keine Schichten vorhanden',
      "use-hierarchical-rows": true,
      "searchable-columns": ['driver', 'sequenceNumber', 'licenseNumber', 'startAt', 'endAt', 'finalTotalAmount'],
      "search-placeholder": 'Schichten durchsuchen...'
    },
    on: {
      "sort-changed": _vm.handleSortChanged,
      "row-click": _vm.handleShiftClick,
      "toggle-expand": _vm.handleToggleExpand,
      "row-hover": function rowHover(args) {
        return _vm.$emit('onRowHover', args);
      },
      "row-leave": function rowLeave(args) {
        return _vm.$emit('onRowLeave', args);
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h3', [_vm._v("Schichten")])];
      },
      proxy: true
    }, {
      key: "column-driver",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: 'Fahrer ändern',
            expression: "'Fahrer ändern'"
          }],
          staticClass: "driver-name",
          style: {
            color: item.hasError ? 'var(--color-red-light)' : 'inherit'
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.handleDriverNameClick(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.driver) + " ")])];
      }
    }, {
      key: "column-finalTotalAmount",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          class: {
            'warning-text': item.hasWarning
          }
        }, [_vm._v(" " + _vm._s(item.finalTotalAmount) + " ")])];
      }
    }, {
      key: "column-subtractedTotalAmount",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.subtractedTotalAmount !== '--:--' ? _c('span', {
          staticClass: "warning-text"
        }, [_vm._v(" " + _vm._s(item.subtractedTotalAmount) + " ")]) : _c('span', [_vm._v(_vm._s(item.subtractedTotalAmount))])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }